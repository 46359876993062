<template>
  <div class="learn_box">
    <div class="learn_banner">
      <img src="@/assets/images/homeImg/learnBanner.png" alt="" />
    </div>
    <div class="learn_wrap">
      <div class="learn_wrap_header">
        <div class="header_item">
          <div
            class="header_item_name"
            v-if="
              learnData != null &&
              learnData.learningTaskLevelProgressList != null
            "
          >
            {{
              learnData.learningTaskLevelProgressList[levelInfo.levelIndex]
                .taskLevelName
            }}
          </div>
          <div class="header_item_title">当前关卡</div>
        </div>
        <div class="header_line"></div>
        <div class="header_item">
          <div class="header_item_name">{{ learnData.levelCount }}</div>
          <div class="header_item_title">关卡数</div>
        </div>
        <div class="header_line"></div>
        <div class="header_item">
          <div class="header_item_name">{{ learnData.finishedLevelCount }}</div>
          <div class="header_item_title">已完成</div>
        </div>
        <div class="header_line"></div>
        <div class="header_item">
          <div class="header_item_name">
            {{ learnData.levelCount - learnData.finishedLevelCount }}
          </div>
          <div class="header_item_title">未完成</div>
        </div>
        <div class="header_line"></div>
        <div class="header_item">
          <div class="header_item_name">
            {{
              learnData.completeRatio != null
                ? learnData.completeRatio
                : 0
            }}%
          </div>
          <div class="header_item_title">闯关进度</div>
        </div>
      </div>
      <div class="learn_play">
        <div class="learn_play_box">
          <div class="video_box">
            <div id="player" v-if="courserData.type === 'INNER'"></div>
            <div class="watermark" v-if="courserData.type === 'INNER'">
              <div class="watermark__item watermark__item_name">
                {{ userName }}
              </div>
              <div class="watermark__item watermark__item_number">
                {{ workNo }}
              </div>
            </div>
            <iframe
              v-else-if="courserData.type === 'OUTER'"
              :src="courserData.videoSource"
              seamless
              frameborder="0"
              width="938px"
              height="529px"
              scrolling="no"
              sandbox="allow-scripts allow-same-origin allow-popups"
            ></iframe>
          </div>
        </div>
        <div class="learn_play_right">
          <task
            :list="learnData.learningTaskLevelProgressList"
            :studyType="learnData.studyType"
            :status="learnData.taskExpireStatus"
            :isClick="isClick"
            :passFlag="passFlag"
          />
        </div>
        <a-modal
          title="验证"
          :visible="visible"
          :closable="false"
          :keyboard="false"
          :maskClosable="false"
          okText="确认"
          cancelText="取消"
          :confirm-loading="confirmLoading"
          :cancel-button-props="{ props: { disabled: true } }"
          @ok="handleOk"
        >
          <p class="weight__border">
            剩余时间:
            <span style="color: red">{{ popTimeText }}</span>
          </p>
          <p class="weight__border">{{ question }}</p>
          <p class="flex flex__align-center">
            你的答案是:
            <a-input
              style="width: 200px"
              placeholder="请输入"
              v-model="myAnswer"
              @pressEnter="pressEnter"
            />
          </p>
        </a-modal>
      </div>
    </div>
  </div>
</template>

<script>
import task from "@/components/task-list";
import {
  getVideoPlayerSafe,
  getCourse,
  setComment,
  getComment,
  getPdfData,
  setCourseRecord,
} from "@/api/learning";
import { getTaskData } from "@/api";
// import { downloadFile } from "@/utils/tools.js";
// import { staticDomainURL } from "@/config";
import Vue from "vue";
import { USER_NAME, WORK_NO } from "@/store/mutation-types.js";
import { apiBaseUrl } from "@/config";
import { ACCESS_TOKEN } from "@/store/mutation-types";
export default {
  components: { task },
  data() {
    return {
      visible: false,
      confirmLoading: false,
      activeKey: "1",
      question: "",
      answer: "",
      myAnswer: "",
      loading: false,
      taskId: "",
      commit: "",
      taskPathId: Vue.ls.get("TASK_PATH_ID"),
      userName: Vue.ls.get(USER_NAME),
      workNo: Vue.ls.get(WORK_NO),
      player: null,
      recordId: null,
      vodPlayerJs: "https://player.polyv.net/script/player.js",
      vid: "",
      videoData: {},
      courserData: {},
      taskLevelId: null,
      learnData: {},
      commitData: [],
      topData: {},
      pdfData: [],
      tab: "课程资料",
      timer: null,
      isProgress: false,
      popTime: null,
      popTimeText: "00:00",
      isClick: false,
      passFlag: false,
      palyState: 0, // 0是初始化完成暂未开始播放，1是用户首次播放，2是用户自己暂停了播放，3是用户自己恢复了播放。4是网页最小化或者切换网页tab时暂停，5是回到当前页恢复播放了
    };
  },
  created() {
    this.taskId = Vue.ls.get("TASK_ID");
    this.getData();
    if (this.$route.query.course) {
      if (this.$route.query.id) this.id = this.$route.query.id;
      this.courseId = this.$route.query.course;
      this.taskLevelId = this.$route.query.taskLevelId;
      this.getCourseData();
    }
    document.addEventListener("visibilitychange", () => {
      let that = this;
      if (document.visibilityState === "hidden") {
        // 离开当前tab标签或者网页最小化了
        console.log("将要离开", that.palyState);
        if (that.palyState == 0 || that.palyState == 2) {
          return;
        } else {
          that.palyState = 4;
          that.player.j2s_pauseVideo();
        }
      } else {
        // 回到当前网页
        console.log("回来了", that.palyState);
        if (that.palyState == 0 || that.palyState == 2) {
          return;
        } else {
          that.palyState = 5;
          that.player.j2s_resumeVideo();
        }
      }
    });
  },
  methods: {
    // 获取学习任务
    getData() {
      getTaskData({
        taskId: this.taskId,
        taskPathId: this.taskPathId,
      }).then((res) => {
        this.learnData = res.result;
        if(this.learnData.completeRatio != null){
          this.learnData.completeRatio = (this.learnData.completeRatio*100).toFixed(2)
        }
        let level =
          Number(res.result.finishedLevelCount) < Number(res.result.levelCount)
            ? res.result.learningTaskLevelProgressList[
                res.result.finishedLevelCount
              ]
            : res.result.learningTaskLevelProgressList[
                res.result.finishedLevelCount - 1
              ];
        console.log("levellevellevellevel", level);
        let levelIndex =
          Number(res.result.finishedLevelCount) < Number(res.result.levelCount)
            ? res.result.finishedLevelCount
            : res.result.finishedLevelCount - 1;
        let courseIndex = null;
        for (let i = 0; i < level.learningTaskSubTaskProgressList.length; i++) {
          if (
            level.learningTaskSubTaskProgressList[i].progressStatus ==
            "NOT_START"
          ) {
            courseIndex = i;
            break;
          }
        }
        console.log("courseIndex", courseIndex != "");
        let levelInfo = {
          levelIndex: levelIndex,
          courseIndex:
            courseIndex != null
              ? courseIndex
              : level.learningTaskSubTaskProgressList.length - 1,
        };
        this.levelInfo = levelInfo;
      });
    },
    // 获取课程详情
    getCourseData() {
      getCourse({
        id: this.courseId,
        taskType: "POSITION_TASK",
        taskPathId: this.taskPathId,
        taskLevelId: this.taskLevelId,
      }).then((res) => {
        console.log(res.result, "我是课程详情哦");
        this.courserData = res.result;
        this.popupList = res.result.popupList;
        if (this.courserData.type === "INNER") {
          this.vid = res.result.videoSource;
          this.getVideoData();
        } else if (this.courserData.type === "OUTER") {
          // window.open(this.courserData.videoSource)
          this.uploadCourse(this.courserData.durationIntValue);
        } else if (this.courserData.type === "OFFLINE") {
          this.$message.warning("当前不支持播放线下课程");
          this.$router.back();
        }
      });
    },
    // 获取视频凭证
    getVideoData() {
      getVideoPlayerSafe({
        vid: this.vid,
      }).then((res) => {
        this.videoData = res.result;
        this.loadPlayerScript(this.loadPlayer);
      });
    },
    // 获取课程评论
    getComment() {
      getComment({
        courseId: this.courseId,
      }).then((res) => {
        this.commitData = res.result;
        let rows = this.commitData.filter((item) => item.topFlag === 1);
        this.topData = rows[0];
      });
    },
    // 获取课程资料
    getPdfData() {
      getPdfData({
        courseId: this.courseId,
      }).then((res) => {
        if (res.result.length != 0) {
          this.tab = "课程资料" + "(" + res.result.length + ")";
        } else {
          this.tab = "课程资料";
        }
        console.log(
          res.result[0].fileUrl.slice(res.result[0].fileUrl.lastIndexOf(".")),
          "pdf数据"
        );
        // lastIndexOf   slice()
        this.pdfData = res.result;
      });
    },
    // 切换tab
    onChange(value) {
      this.activeKey = value;
    },
    // 添加评论
    commitFun(value) {
      // if (isNull(this.value) === false) {
      //   this.$message.warning("不能添加空评论哦!");
      //   return false;
      // }
      if (value.match(/^[ ]*$/)) {
        this.$message.warning("不能添加空评论哦!");
        return false;
      }
      if (value != "") {
        this.loading = true;
        setComment({
          content: value,
          courseId: this.courseId,
        })
          .then((res) => {
            this.commit = "";
            this.commitData = res.result;
            let rows = this.commitData.filter((item) => item.topFlag === 1);
            this.topData = rows[0];
            this.$message.success("添加成功!");
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.$message.warning("不能提交空评论哦!");
      }
    },
    // 下载
    downFun(record) {
      let token = Vue.ls.get(ACCESS_TOKEN);
      var url = apiBaseUrl + "/api/datum/download/" + token + "/" + record.id;
      window.open(url);
    },

    // 弹窗验证答案
    handleOk() {
      if (this.myAnswer === this.answer) {
        if (this.popTime === null && this.popTimeText === "00:00 验证超时") {
          // 如果验证已超时, 则刷新页面
          window.location.reload()();
        }
        clearInterval(this.popTime);
        this.popTime = null;
        this.popTimeText = "00:00";
        this.visible = false;
        this.player.j2s_resumeVideo(); // 恢复播放暂停的视频
        this.myAnswer = "";
        this.$message.success("验证成功!");
      } else {
        this.$message.error("答案错误,请重新输入!");
      }
    },
    pressEnter() {
      this.handleOk();
    },
    // 上传当前课程任务记录
    uploadCourse(time) {
      let player = JSON.parse(this.player.j2s_realPlayStatus());
      console.log(player.playduration, "播放时长");
      let params = {
        id: this.recordId,
        contentId: this.courseId,
        contentName: this.courserData.name,
        duration: time,
        actualDuration: player.playduration,
        subTaskType: "COURSE",
        contentType: "POSITION_TASK",
        subTaskId: this.id,
        subtaskName: this.id ? this.courserData.name : null,
        taskPathId: this.id ? this.taskPathId : null,
      };
      setCourseRecord(params)
        .then((res) => {
          this.recordId = res.result.id;
          // 视频播放完毕时
          if (res.result.passFlag) {
            // this.player.on('s2j_onPlayOver', () => {})
            this.getData();
            this.courserData.passFlag = true;
          }
        })
        .catch(() => {
          this.$message.error("系统繁忙!");
        });
    },

    // 监听弹窗时间
    popFun(time) {
      if (this.popupList != null) {
        this.popupList.forEach((item) => {
          if (Math.abs(time - item.start) < 3 && !item.isPop) {
            let time = 300;
            this.popTimeText = "00:00";
            if (!this.isProgress) {
              this.popTime = setInterval(() => {
                time--;
                this.popTimeText = this.setTimes(time);
                if (time <= 0) {
                  this.isProgress = true;
                  clearInterval(this.popTime);
                  this.popTime = null;
                  this.popTimeText = "00:00 验证超时";
                }
              }, 1000);
            }
            this.player.j2s_pauseVideo(); // 暂停播放
            this.visible = true;
            this.question = item.question;
            this.answer = item.answer;
            item.isPop = true;
          }
        });
      }
    },

    // 播放暂停或者结束要处理的事情
    pauseOrOverFun() {
      // 提交一次记录
      if (this.isProgress) return false;
      var videoTimes = Math.floor(this.player.j2s_getCurrentTime());
      this.uploadCourse(videoTimes);
      // 清楚定时器
      clearInterval(this.timer);
      this.timer = null;
      let total = this.player.j2s_getDuration();
      console.log(videoTimes, "当前的播放时长");
      console.log(total, "视频总时长");
      if (videoTimes === total) this.passFlag = true;
    },

    loadPlayerScript(callback) {
      this.isClick = true;
      if (!window.polyvPlayer) {
        const myScript = document.createElement("script");
        myScript.setAttribute("src", this.vodPlayerJs);
        myScript.onload = callback;
        document.body.appendChild(myScript);
      } else {
        callback();
      }
    },
    // 处理时间
    setTimes(x) {
      var durationValue = this.$moment.duration(x, "seconds");
      var hours = Math.floor(durationValue.asHours());
      var mins = Math.floor(durationValue.asMinutes()) - hours * 60;
      var seconds = Math.floor(durationValue.asSeconds()) - mins * 60;
      var h = "";
      var m = "";
      var s = "";
      hours < 10 ? (h = "0" + hours) : (h = hours);
      mins < 10 ? (m = "0" + mins) : (m = mins);
      seconds < 10 ? (s = "0" + seconds) : (s = seconds);
      // let time = ''
      console.log(h);
      return m + ":" + s;
    },
    loadPlayer() {
      const polyvPlayer = window.polyvPlayer;
      this.player = polyvPlayer({
        wrap: "#player",
        width: 938,
        height: 527,
        vid: this.vid,
        playsafe: this.videoData.token,
        ts: this.videoData.ts,
        sign: this.videoData.sign,
        allowFullscreen: false, // 禁止全屏
        ban_seek: this.courserData.passFlag ? "off" : "on", // 禁止拖动滚动条
        speed: false,
        autoplay: true,
        // show_rate: 1, // 只允许流畅画质
        df: 2,
        // showAuto: false
      });
      // 初始化播放器成功时
      this.player.on("s2j_onPlayerInitOver", () => {
        console.log("我是刚刚加载完成", this.palyState);
        this.palyState = 0;
        // 播放器初次播放或者从暂停恢复播放时
        this.player.on("s2j_onVideoPlay", () => {
          console.log("我是第一次播放或者恢复播放", this.palyState);
          this.palyState = 3;
          // 实时获取播放时长
          let times =
            Math.floor(this.player.j2s_getCurrentTime()) > 0
              ? Math.floor(this.player.j2s_getCurrentTime())
              : 0;
          this.timer = setInterval(() => {
            // 获取视频当前的播放进度 xxs
            var videoTimes = Math.floor(this.player.j2s_getCurrentTime());

            if (times != 0 && times % 30 === 0 && !this.isProgress) {
              // 每30秒提交一次记录
              this.uploadCourse(videoTimes);
            }
            if (
              this.popupList != null &&
              !this.isProgress &&
              !this.courserData.passFlag
            ) {
              this.popFun(videoTimes);
            }

            times += 1;
          }, 1000);
        });
        //初次播放
        this.player.on("s2j_onPlayStart", () => {
          console.log("我第一次播放", this.palyState);
          this.palyState = 1;
        });
        // // 加载好开始播放触发
        // this.player.on("s2j_onReadyPlay", () => {
        //   console.log("加载好开始播放触发  s2j_onReadyPlay");
        // });
        // 视频暂停时
        this.player.on("s2j_onVideoPause", () => {
          this.pauseOrOverFun();
          this.palyState = this.palyState == 4 ? this.palyState : 2;
          console.log("我暂停了一下", this.palyState);
        });
        // 监听拖拽进度条
        this.player.on("s2j_onVideoSeek", () => {
          if (this.courserData.passFlag) {
            clearInterval(this.timer);
            this.timer = null;
          }
        });
        this.player.on("s2j_onPlayOver",()=>{
          console.log('哈哈哈哈')
          this.pauseOrOverFun();
        })
      });
    },
  },
  watch: {
    $route() {
      clearInterval(this.popTime);
      this.passFlag = false;
      this.isClick = false;
      this.popTime = null;
      this.popTimeText = "00:00";
      this.recordId = null;
      this.getData();
      this.player.destroy();
      clearInterval(this.timer);
      this.timer = null;
      if (this.$route.query.course) {
        this.id = this.$route.query.id;
        this.courseId = this.$route.query.course;
        this.taskLevelId = this.$route.query.taskLevelId;
        this.getCourseData();
        this.getComment();
        this.getPdfData();
      }
    },
  },
  beforeDestroy() {
    this.recordId = null;
    if (this.timer) {
      clearInterval(this.timer);
      this.timer = null;
    }
    if (this.player) {
      this.player.destroy();
    }
  },
};
</script>

<style lang = "scss" scoped>
.learn_box {
  .learn_banner {
    width: 100%;
    img {
      width: 100%;
      height: auto;
    }
  }
  .learn_wrap {
    width: 1200px;
    padding: 48px 0 192px;
    margin: 0 auto;
    .learn_wrap_header {
      width: 1200px;
      background: #5db4ff;
      box-shadow: 0px 5px 10px 0px #a7d6ff;
      border-radius: 15px;
      margin-bottom: 48px;
      display: flex;
      align-items: center;
      padding: 20px 0;
      .header_item {
        flex: 1;
        text-align: center;
        .header_item_name {
          font-size: 36px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #ffffff;
          line-height: 36px;
        }
        .header_item_title {
          font-size: 24px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #ffffff;
          line-height: 24px;
          margin-top: 21px;
        }
      }
      .header_line {
        width: 2px;
        height: 66px;
        background: #ffffff;
      }
    }
    .learn_play {
      width: 1200px;
      display: flex;
      justify-content: space-between;
      .learn_play_box {
        width: 938px;
        height: 529px;
        background: #989898;
        .video_box {
          width: 100%;
          position: relative;
        }
      }
    }
  }
}
.video {
  &__box {
    width: 940px;
  }
  &__title {
    /* width: 100%; */
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #ffffff;
    line-height: 42px;
    /* background: linear-gradient(90deg, #c4a468, #f6bea5); */
    box-sizing: border-box;
    padding-left: 30px;
    /* position: relative; */
    /* width: 125px; */
    height: 42px;
    background: linear-gradient(90deg, #e10b0b, #ee8544);
    padding-right: 10px;
    border-radius: 0px 10px 10px 0px;
    position: absolute;
    z-index: 9;
    &::before {
      content: "";
      display: block;
      position: absolute;
      top: 50%;
      left: 15px;
      transform: translateY(-50%);
      width: 4px;
      height: 22px;
      background: #fff;
    }
  }
  &__tab {
    background: #ffffff;
    border: 1px solid #e4e4e4;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
    margin-top: 20px;
    &-content {
      padding: 0px 36px 26px;
    }
    &_color {
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #333333;
    }
  }
  &__question {
    &-tag {
      color: #ff5613;
    }
    &-reply {
      padding-left: 50px;
    }
  }
  &__answers {
    padding: 0 48px;
    margin-top: 16px;

    &-item {
      margin-top: 6px;
    }
    &-to {
      color: #0e53ef;
      margin-right: 10px;
    }
    &-text {
      font-weight: normal;
      word-break: break-all;
      word-wrap: break-word;
    }
    &-user {
      white-space: nowrap;
    }
  }
  &__files {
    margin-bottom: 10px;
    &-text {
      color: #ff5613;
      font-size: 14px;
    }
    &-name {
      color: #333333;
      font-size: 14px;
      transition: all 0.4s;
      &:hover {
        text-decoration: underline;
        color: #376cde;
      }
    }
  }
  &__input {
    margin-top: 20px;
  }
}

.watermark {
  position: absolute;
  top: 50%;
  right: 20px;
  z-index: 9;
  text-align: center;
  &__item {
    color: #8a8a8a;
    font-size: 18px;
    font-weight: bold;
  }
}

.tools__item-icon {
  margin-left: 10px;
  margin-top: 4px;
}
</style>
